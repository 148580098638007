




































import CTag from '~/components/shared/configurable/form/tag/CTag.vue'
import { Tag } from '~/components/shared/configurable/form/types'
import {
  defineComponent,
  nextTick,
  PropType,
  ref,
  toRefs,
  watch
} from '@nuxtjs/composition-api'
import { useSnackbar } from '~/compositions/snackbar'
import { useI18n } from '~/compositions/i18n'
import { useFormComponent } from '~/compositions/form-component'

export default defineComponent({
  components: { CTag },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    tags: {
      type: Array as PropType<Tag[]>,
      required: false,
      default() {
        return []
      }
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: false,
      default: undefined
    }
  },
  setup(props, { emit }) {
    const { tags, required } = toRefs(props)
    const inputText = ref('')
    const internalTags = ref(tags.value)
    const focused = ref(false)
    const snackbar = useSnackbar()
    const inputTemplateRef = ref<any>(null)
    const { t } = useI18n()

    const { controlClasses } = useFormComponent(
      required,
      ref(false),
      ref([]),
      ref(false)
    )
    watch(tags, newTags => (internalTags.value = newTags))

    function handleBackspace(previousTextValue: string) {
      if (previousTextValue.length === 0) {
        internalTags.value.pop()
        emit('change', internalTags.value)
      }
    }

    function handleTagClose(text: string) {
      internalTags.value = internalTags.value.filter(
        (tag: Tag) => tag.text !== text
      )
      emit('change', internalTags.value)
    }

    async function addTag(text: string) {
      const tagIsEmpty = text.trim().length <= 0
      if (tagIsEmpty) {
        return
      }
      const tagAlreadyExists = internalTags.value.some(
        (tag: Tag) => tag.text === text
      )
      if (tagAlreadyExists) {
        snackbar.info(t('this tag already exists'))
        inputTemplateRef.value.blur()
        return
      }
      const tag: Tag = {
        text
      }
      inputText.value = ''
      internalTags.value.push(tag)
      emit('change', internalTags.value)
      await nextTick()
      inputTemplateRef.value.focus()
    }

    function onContainerClick() {
      if (inputTemplateRef.value) {
        inputTemplateRef.value.focus()
      }
    }
    function onInput(text: string) {
      if (text.length && text.charAt(text.length - 1) === ' ') {
        addTag(text)
      }
      emit('input', text)
    }

    return {
      internalTags,
      inputText,
      handleBackspace,
      handleTagClose,
      addTag,
      focused,
      inputTemplateRef,
      onContainerClick,
      onInput,
      controlClasses
    }
  }
})
