









































































































































































import Vue from 'vue'
import vClickOutside from 'v-click-outside'

import { faEraser } from '@fortawesome/free-solid-svg-icons'
import { findDeep } from '~/utils/object'
import CTreeSelectNode from '~/components/shared/configurable/form/select/tree/CTreeSelectNode.vue'
import { getParentPosition } from '~/utils/dom'
import { mapGetters } from 'vuex'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import CCustomSelectHeader from '~/components/shared/configurable/form/select/custom/CCustomSelectHeader.vue'

export default Vue.extend({
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    CCustomSelectHeader,
    CTreeSelectNode
  },
  props: {
    hasBackground: {
      type: Boolean,
      required: false,
      default: false
    },
    onlyExisting: { type: Boolean, default: false },
    placeholder: { type: String, required: false, default: '' },
    name: { type: String, required: false, default: '' },
    options: { type: Array, required: true },
    value: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    valueCol: { type: String, default: 'value' },
    nameCol: { type: String, default: 'name' },
    countCol: { type: String, default: 'count' },
    childrenCol: { type: String, default: 'children' },
    expanded: { type: Boolean, default: false },
    header: { type: Boolean, default: true },
    loading: { type: Boolean },
    disable: { type: Boolean, default: false },
    showCounts: { type: Boolean, default: true },
    withoutEraser: { type: Boolean, default: false },
    withoutClose: { type: Boolean, default: false },
    bodyClasses: {
      type: String,
      required: false,
      default: ''
    },
    flat: {
      type: Boolean,
      default: true
    },
    hideZeroCountOptions: {
      type: Boolean,
      default: false
    },
    isQuickSearch: {
      type: Boolean,
      default: false
    },
    isProfessionalQuickSearch: {
      type: Boolean,
      default: false
    },
    isRentalQuickSearch: {
      type: Boolean,
      default: false
    },
    headerClass: {
      type: String,
      required: false,
      default: ''
    },
    mobileModalTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isMobileModalVisible: false,
      dropdownPositionalCssRules: {
        height: null,
        left: null,
        top: null,
        width: null
      },
      selectedLabels: [],
      selectedIds: [],
      open: false,
      icons: {
        eraser: faEraser
      },
      optionMap: this.getOptionMap() || new Map()
    }
  },
  computed: {
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    selectedValuesThreshold() {
      if (this.isRentalQuickSearch) return 2

      if (this.isProfessionalQuickSearch) return 4

      if (
        this.isQuickSearch &&
        !this.isProfessionalQuickSearch &&
        this.name === 'category'
      )
        return 3

      if (this.isQuickSearch && this.name === 'location2') return 1

      // fuel_type as default return
      return 0
    },
    derivedPlaceholder() {
      if (
        this.isProfessionalQuickSearch &&
        this.selectedValue.length > this.selectedValuesThreshold
      ) {
        return this.$i18n.tc(
          '1 selected | {count} selected',
          this.selectedValue.length - this.selectedValuesThreshold
        )
      }

      if (
        this.isRentalQuickSearch &&
        this.selectedValue.length > this.selectedValuesThreshold
      ) {
        return this.$i18n.tc(
          '1 selected | {count} selected',
          this.selectedValue.length - this.selectedValuesThreshold
        )
      }

      if (
        this.isQuickSearch &&
        !this.isProfessionalQuickSearch &&
        this.name === 'category' &&
        this.selectedValue.length > this.selectedValuesThreshold
      ) {
        return this.$i18n.tc(
          '1 selected | {count} selected',
          this.selectedValue.length - this.selectedValuesThreshold
        )
      }

      if (
        this.isQuickSearch &&
        this.name === 'fuel_type' &&
        this.selectedValue.length > this.selectedValuesThreshold
      ) {
        return this.$i18n.tc(
          '1 selected | {count} selected',
          this.selectedValue.length
        )
      }

      if (
        this.isQuickSearch &&
        this.name === 'location2' &&
        this.selectedValue.length > this.selectedValuesThreshold
      ) {
        return this.$i18n.tc(
          '1 selected | {count} selected',
          this.selectedValue.length - this.selectedValuesThreshold
        )
      }

      if (
        this.isQuickSearch &&
        this.name === 'condition' &&
        this.selectedValue.length > this.selectedValuesThreshold
      ) {
        return this.$i18n.tc(
          '1 selected | {count} selected',
          this.selectedValue.length - this.selectedValuesThreshold
        )
      }

      return false
    },
    selectedValue: {
      get() {
        return JSON.parse(JSON.stringify(this.value))
      },
      set(value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  },
  watch: {
    async selectedValue() {
      await this.updateSelectedLabels()
      this.$emit('selected-values', this.selectedIds)
    },
    options() {
      this.optionMap = this.getOptionMap()
      this.updateSelectedLabels()
    }
  },
  mounted() {
    this.updateSelectedLabels()
    this.updateDropdownPosition()
  },
  methods: {
    handleClickOutside(event: PointerEvent) {
      if (this.flat) {
        return
      }
      const element = this.$refs.header.$el as Node

      if (
        this.open &&
        event.target !== element &&
        !element.contains(event.target as Node)
      ) {
        this.hideDropdown()
      }
    },
    hideDropdown() {
      this.open = false
    },
    showNode(option) {
      if (!this.hideZeroCountOptions) {
        return true
      }
      return option[this.countCol] !== 0
    },
    async updateDropdownPosition() {
      await this.$nextTick()
      const parentPos = getParentPosition(
        this.$refs.carzillaTreeSelect,
        this.$refs.dropdown
      )

      if (!parentPos) {
        // Throws parentPos undefined in dsite search page if unguarded.
        return
      }

      this.dropdownPositionalCssRules = parentPos.style
    },
    updateSelectedLabels() {
      if (!this.optionMap.size) {
        return []
      }

      const selectedLeaves = this.selectedValue.filter(value => {
        const option = this.optionMap.get(value)
        if (!option) {
          return false
        }

        const someChildIsSelected =
          option.children &&
          option.children.some(child =>
            this.selectedValue.some(sv => sv === child.value)
          )
        return !someChildIsSelected
      })
      this.selectedIds = selectedLeaves.map(
        value => this.optionMap.get(value).value
      )
      this.selectedLabels = selectedLeaves.map(
        value => this.optionMap.get(value)[this.nameCol]
      )
    },
    getOptionMap() {
      const map = new Map()
      const flattenOptionTree = (option, parentId) => {
        map.set(option.value, { ...option, parentId })
        option.children &&
          option.children.forEach(c => flattenOptionTree(c, option.value))
      }
      this.options.forEach(option => flattenOptionTree(option, null))
      return map
    },
    getSeparator(index) {
      if (
        this.selectedLabels.length > 1 &&
        index < this.selectedLabels.length - 1
      ) {
        return ', '
      } else {
        return ''
      }
    },
    async toggle() {
      if (this.isMobile) {
        this.isMobileModalVisible = !this.isMobileModalVisible
        return
      }

      this.open = !this.open
      await this.$nextTick()
      await this.updateDropdownPosition()
    },
    async close() {
      if (this.isMobile) {
        this.isMobileModalVisible = false
        return
      }

      this.open = false
      await this.$nextTick()
      this.$emit('close')
      await this.updateDropdownPosition()
    },
    handleChange(newSelections: number[]) {
      if (newSelections.length < this.selectedValue.length) {
        const difference = this.selectedValue
          .filter(x => !newSelections.includes(x))
          .concat(newSelections.filter(x => !this.selectedValue.includes(x)))
        this.deselect(newSelections, difference[0])
      } else {
        this.selectedValue = newSelections
      }
    },
    deselect(newSelections, id) {
      const idsToDeselect = this.getIdsToDeselect(id, [])
      newSelections = newSelections.filter(x => !idsToDeselect.includes(x))
      this.selectedValue = newSelections
    },
    getIdsToDeselect(id, ids) {
      const toDeselect = findDeep({
        object: this.options,
        key: this.valueCol,
        value: id
      })
      if (toDeselect[this.childrenCol]) {
        toDeselect[this.childrenCol].forEach(child => {
          ids.push(child[this.valueCol])
          this.getIdsToDeselect(child[this.valueCol], ids)
        })
      }

      return ids
    }
  }
})
