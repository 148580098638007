













































































import Vue, { PropOptions } from 'vue'
import {
  DropdownDirection,
  OptionGroup
} from '~/components/shared/configurable/form/select/types'
import CCustomSelectHeader from '~/components/shared/configurable/form/select/custom/CCustomSelectHeader.vue'
import { getParentPosition } from '~/utils/dom'
import CCustomSelectDropdownGrouped from '~/components/shared/configurable/form/select/custom/dropdown/CCustomSelectDropdownGrouped.vue'
import CCustomSelectNativeSelect from '~/components/shared/configurable/form/select/custom/CCustomSelectNativeSelect.vue'
import { Option, Size } from '~/models/shared/types'
import { textExistsInString } from '~/utils/string'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { mapGetters } from 'vuex'
import { v4 as uuid } from 'uuid'
export default Vue.extend({
  components: {
    CCustomSelectHeader,
    CCustomSelectDropdownGrouped,
    CCustomSelectNativeSelect
  },
  props: {
    value: {
      type: [String, Number, Array],
      required: false,
      default() {
        return []
      }
    },
    placeholder: {
      type: String,
      required: false,
      default() {
        return this.$t('all')
      }
    },
    optionGroups: {
      type: Array,
      required: true,
      default() {
        return []
      }
    } as PropOptions<OptionGroup[]>,
    options: {
      type: Array,
      required: false,
      default() {
        return []
      }
    } as PropOptions<Option[]>,
    dropdownOpen: {
      type: Boolean,
      required: false,
      default: false
    },
    headerClasses: {
      type: String,
      required: false,
      default: ''
    },
    dropdownClasses: {
      type: String,
      required: false,
      default: ''
    },
    headerPreText: {
      type: String,
      required: false,
      default: ''
    },
    size: {
      type: String,
      required: false,
      default: Size.MEDIUM
    } as PropOptions<Size>,
    multiSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    showAllButton: {
      type: Boolean,
      required: false,
      default: false
    },
    searchPlaceholder: {
      type: String,
      required: false,
      default() {
        return this.$t('search')
      }
    },
    showDropdownFooter: {
      type: Boolean,
      required: false,
      default: false
    },
    canDeselectSingle: {
      type: Boolean,
      required: false,
      default: false
    },
    headerTitle: {
      type: [String, null],
      required: false,
      default: null
    },
    noPortal: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    state: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      q: '',
      internalDropdownOpen: this.dropdownOpen,
      dropdownPositionalCssRules: {
        height: null,
        left: null,
        top: null,
        width: null
      },
      dropdownDirection: DropdownDirection.Below,
      dropdownId: uuid()
    }
  },
  computed: {
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile',
      isTablet: 'isTablet'
    }),
    showNativeSelect() {
      return this.isMobile || this.isTablet
    },
    flatOptionGroupsOptions() {
      return this.optionGroups.flatMap(group => group.options)
    },
    allOptions() {
      return this.flatOptionGroupsOptions.concat(this.options)
    },
    selectedOptions() {
      if (!this.internalValue) {
        return []
      }

      return this.allOptions.filter(option =>
        this.internalValue.includes(option?.value)
      )
    },
    internalValue() {
      if (!this.value) {
        if (this.multiSelect) {
          return []
        }
        return null
      }
      return Array.isArray(this.value) ? this.value : [this.value]
    },
    allAreSelected() {
      return Array.isArray(this.internalValue)
        ? this.internalValue.length === this.allOptions.length
        : false
    },
    filteredOptionGroups() {
      if (this.q) {
        // TODO: maybe perform this in a more elegant way
        const filteredOptionGroups = []
        this.optionGroups.forEach(group => {
          const filteredGroupOptions = group.options.filter(option =>
            textExistsInString(this.q, option.text)
          )
          if (filteredGroupOptions.length > 0) {
            filteredOptionGroups.push({
              ...group,
              options: filteredGroupOptions
            })
          }
        })
        return filteredOptionGroups
      } else {
        return this.optionGroups
      }
    },
    filteredOptions() {
      if (this.q) {
        return this.options.filter(option =>
          textExistsInString(this.q, option.text)
        )
      } else {
        return this.options
      }
    },
    nothingSelected() {
      return (
        this.internalValue === null ||
        (Array.isArray(this.internalValue) && this.internalValue.length === 0)
      )
    }
  },
  mounted() {
    this.updateDropdownPosition()
  },
  methods: {
    clearSearchInput() {
      this.q = ''
    },
    handleDeselectOption(option: Option) {
      this.internalValue.splice(this.internalValue.indexOf(option.value), 1)
      this.emitValue(this.internalValue)
    },
    handleSelectAll(selectAllChecked) {
      if (selectAllChecked) {
        this.emitValue(this.allOptions.map(option => option.value))
      } else {
        this.emitValue([])
      }
    },
    handleDeselectAll() {
      this.emitValue([])
    },
    handleSearch(text) {
      this.q = text
    },
    handleSelect(value) {
      this.emitValue(value)
      this.internalDropdownOpen = false
    },
    emitValue(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
    handleClearAll() {
      this.emitValue([])
      this.q = ''
    },
    handleMultiSelect(value) {
      this.emitValue(value)
    },
    async toggleDropdown() {
      // not using this.internalDropdownOpen = !this.internalDropdownOpen since open/close have some different handlings
      this.internalDropdownOpen
        ? await this.closeDropdown()
        : await this.openDropdown()
    },
    async openDropdown() {
      this.internalDropdownOpen = true
      await this.$nextTick()
      this.$emit('show')
      await this.updateDropdownPosition()
    },
    async closeDropdown() {
      this.internalDropdownOpen = false
      await this.$nextTick()
      this.$emit('hide')
    },
    async updateDropdownPosition() {
      await this.$nextTick()
      const parentPos = getParentPosition(
        this.$refs.carzillaOptionGroupsSelect,
        this.$refs[`dropdown-${this.dropdownId}`]?.$el
      )

      if (!parentPos) {
        // Throws parentPos undefined in dsite search page if unguarded.
        return
      }

      this.dropdownPositionalCssRules = parentPos.style

      this.dropdownDirection = parentPos.openDirection
    }
  }
})
