



















































































































































































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import vClickOutside from 'v-click-outside'
import { debounce } from '~/utils/function'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import SearchbarCategoryDropdown from '~/components/shared/classified/search/searchbar/SearchbarCategoryDropdown.vue'
import CMicrophoneRecorder from '~/components/shared/configurable/CMicrophoneRecorder.vue'
import SearchbarInCategoryButtons from '~/components/shared/classified/search/searchbar/SearchbarInCategoryButtons.vue'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import {
  CLEAR_ALL,
  SET_PARAM,
  SET_SHOW_SELLERS
} from '~/store/modules/shared/classifieds/search/mutation-types'
import { KeyCode } from '~/constants/keyboard'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { defaultBaseClientUrl } from '~/constants/base-client-url'
import qs from 'qs'
import SearchbarService from '~/services/search-bar/SearchbarService'
import { CategoryId } from '~/models/category/types'
import { ciSearchbarMagnifier } from '~/icons/source/regular/searchbar-magnifier'
import { ciAngleLeftLight } from '~/icons/source/solid/angle-left-light'
import { ciTimes } from '~/icons/source/regular/times'
import { mapDeps } from '~/plugins/dependency-container/utils'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import { APP_NS } from '~/store/modules/shared/app/state'
import { useModalToggle } from '~/compositions/modal-toggle'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import SearchbarSuggestionEntry from '~/components/shared/classified/search/searchbar/suggestions/SearchbarSuggestionEntry.vue'
import { SuggestionType } from '~/models/search-bar'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'

export default defineComponent({
  components: {
    SearchbarSuggestionEntry,
    CMicrophoneRecorder,
    SearchbarCategoryDropdown,
    SearchbarInCategoryButtons
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  setup() {
    const modalToggleFunc = useModalToggle()
    return { modalToggleFunc }
  },
  props: {
    placeholder: {
      type: String,
      required: false,
      default() {
        return this.$t('what are you looking for?')
      }
    },
    customPlaceholders: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => {
        return []
      }
    },
    maxLength: {
      type: Number,
      required: false,
      default: 50
    },
    /* simple - to be used only outside of search in simple styling */
    simple: {
      type: Boolean,
      required: false,
      default: false
    },
    /* simplesStyle - full functionality with simple styling (search supported)   */
    simplesStyle: {
      type: Boolean,
      required: false,
      default: false
    },
    forceCategory: {
      type: Number as PropType<CategoryId>,
      required: false,
      default: undefined
    },
    forceCategoryDropdown: {
      type: Boolean,
      required: false,
      default: false
    },
    forceLegacySearchbar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchQ: '',
      afterSearchQ: '',
      autocompleteSuggestions: [],
      filterSuggestions: [],
      legacySuggestions: [],
      suggestedClassifieds: null,
      cachedSuggestions: new Set(),
      activeSuggestionIndex: -1,
      activeQ: '',
      fetchingAutocomplete: false,
      fetchingLegacy: false,
      fetchingQuery: false,
      fetchingClassifieds: false,
      hoveredSuggestion: -1,
      activeDisplayPhrase: '',
      hasMic: false,
      micRecording: false,
      dontFetchNextFocus: false,
      dontFetchNextInput: false,
      silentlyFocused: false,
      focused: false,
      fullscreen: false,
      inCategory: !this.simple,
      activeSuggestion: null,
      linkToNavigate: '',
      classifiedIndexToNavigate: -1,
      activePlaceholder: 0,
      placeholderInterval: null,
      fadePlaceholder: false,
      suggestAfterFetch: false
    }
  },
  computed: {
    ...mapDeps({ searchbarService: SearchbarService }),
    ...mapState(CLASSIFIED_SEARCH_NS, {
      isFiltersPage: state => state.config?.settings?.isFiltersPage,
      rootCategory: state => state.config?.rootParams?.category,
      currentCategoryId: state => state.category?.id,
      categoryIds: state => state.categoryIds,
      userOwnsSearch: state => state.userOwnsSearch
    }),
    ...mapGetters(APP_NS, {
      isCar: 'isCar'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    ...mapDeps({ legacyUrlService: LegacyUrlService }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      inRootCategory: 'inRootCategory',
      qHandler: 'getQFacet',
      textSearchableCategories: 'getTextSearchSearchableCategories',
      useNewSearchbarInSearch: 'useNewSearchbar',
      getSetting: 'getSetting'
    }),
    ...mapState(DEALER_SITE_NS, {
      websiteId: 'websiteId'
    }),
    forceCategoryIsLegacy(): boolean {
      return [CategoryId.XYMA, CategoryId.PARTS, CategoryId.JOBS].includes(
        this.forceCategory
      )
    },
    useNewSearchbar() {
      // we have a force category that supports new searchbar
      return (
        !this.forceLegacySearchbar &&
        this.forceCategory &&
        !this.forceCategoryIsLegacy
      )
    },
    useLegacySearchbar() {
      if (this.useNewSearchbar) {
        return false
      }
      return (
        this.forceLegacySearchbar ||
        this.forceCategoryIsLegacy ||
        !this.useNewSearchbarInSearch
      )
    },
    qFacet: {
      get() {
        if (this.qHandler?.values?.value) {
          return this.qHandler.values.value
        }
        return ''
      },
      set(newQ) {
        this.$store.commit('classifieds/search/SET_FACETS_SEARCHBAR', {
          q: newQ
        })
      }
    },
    icons() {
      return {
        search: faSearch,
        close: ciTimes,
        arrowLeft: ciAngleLeftLight,
        magnifier: ciSearchbarMagnifier
      }
    },
    showCategories() {
      if (
        this.isCar &&
        this.selectedRootCategoryArray.length &&
        this.textSearchableCategories.length > 1 &&
        !this.userOwnsSearch
      ) {
        return this.forceCategoryDropdown || !(this.isDealerSite || this.simple)
      }

      return false
    },
    showInCategory() {
      return this.useLegacySearchbar && !this.inRootCategory && !this.simple
    },
    applySimplesStyle() {
      return this.simple || (this.simplesStyle && !this.fullscreen)
    },
    showResults() {
      return Boolean(this.suggestions.length)
    },
    showOverlay() {
      if (!this.fullscreen && this.simple) {
        return false
      }
      return this.showResults || this.focused || this.fullscreen
    },
    fetching() {
      return (
        this.fetchingAutocomplete ||
        this.fetchingLegacy ||
        this.fetchingQuery ||
        this.fetchingClassifieds
      )
    },
    selectedCategory() {
      // root category is only used on load
      if (this.inCategory && this.currentCategoryId) {
        return this.currentCategoryId
      }
      return this.selectedRootCategoryArray[0]
    },
    selectedRootCategoryArray() {
      if (this.forceCategory) {
        return [this.forceCategory]
      } else if (this.inCategory && this.categoryIds?.length) {
        return this.categoryIds
      } else if (this.rootCategory) {
        return Array.isArray(this.rootCategory)
          ? this.rootCategory
          : [this.rootCategory]
      }
      // default
      return [CategoryId.CLASSIFIEDS]
    },
    showNewBadge() {
      // when open for dealers, show new badge only for dealers maybe (?)
      return false
    },
    isDealerSite() {
      return this.$dep(DealerSiteService).routeIsOfDealerSite()
    },
    categoryPlaceholders() {
      if (this.isDealerSite) {
        return null
      }
      const placeholders = this.searchbarService.getCategoryPlaceholders(
        this.selectedCategory
      )

      if (placeholders?.length) {
        if (this.customPlaceholders.length) {
          return [...this.customPlaceholders, ...placeholders]
        }

        return [this.placeholder, ...placeholders]
      }
      return null
    },
    placeholders() {
      if (this.customPlaceholders.length) {
        return this.customPlaceholders
      }
      if (this.categoryPlaceholders) {
        return this.categoryPlaceholders
      }
      return []
    },
    placeholderToShow() {
      if (this.micRecording) {
        return this.$t('start talking') + '...'
      }

      if (this.placeholders.length > 0) {
        const indexToShow =
          this.focused && !this.silentlyFocused ? 0 : this.activePlaceholder
        if (this.categoryPlaceholders && this.customPlaceholders.length === 0) {
          const prefix =
            indexToShow > 0 ? `${this.$t('e.g.').toLowerCase()} ` : ''
          return prefix + this.placeholders[indexToShow]
        }
        return this.placeholders[indexToShow]
      }

      return this.placeholder
    },
    inSearchRoute() {
      return (
        this.$route.name?.startsWith('__classifieds_search') ||
        this.getSetting('isDealerSiteSearch') // dsites are always searches and always legacy
      )
    },
    searchContext() {
      if (this.inSearchRoute) {
        if (this.getSetting('isDealerSiteSearch')) {
          if (this.websiteId) {
            return `dsite::${this.websiteId}`
          }
          return 'dsite'
        } else if (this.getSetting('isDealsSearch')) {
          return 'deals'
        } else if (this.userOwnsSearch && !this.simple) {
          return 'myclassifieds'
        }
      }

      return null
    },
    showSearchbar() {
      if (this.inSearchRoute && this.useLegacySearchbar) {
        return Boolean(this.qHandler)
      }
      return true
    },
    showRecentSearchesHeader() {
      return (
        this.historyAutocompleteSuggestions.length === this.suggestions.length
      )
    },
    historyAutocompleteSuggestions() {
      return [...this.autocompleteSuggestions.filter((s: any) => s.fromHistory)]
    },
    autocompleteSuggestionsWithoutHistory() {
      return [
        ...this.autocompleteSuggestions.filter((s: any) => !s.fromHistory)
      ]
    },
    suggestions() {
      let suggClsfd: any[] = []
      if (this.suggestedClassifieds?.classifieds?.length) {
        suggClsfd = [
          ...this.suggestedClassifieds.classifieds.map(s => {
            return { ...s, type: SuggestionType.CLASSIFIED }
          })
        ]
        if (
          this.suggestedClassifieds?.seo_url &&
          this.suggestedClassifieds?.title
        ) {
          suggClsfd.push({
            seoUrl: this.suggestedClassifieds.seo_url,
            title: this.suggestedClassifieds.title,
            type: 'more'
          })
        }
      }

      return [
        ...this.historyAutocompleteSuggestions,
        ...this.filterSuggestions,
        ...this.autocompleteSuggestionsWithoutHistory,
        ...this.legacySuggestions,
        ...suggClsfd
      ]
    }
  },
  watch: {
    inCategory() {
      this.closeSuggestions()
      this.focusSearchbar()
    },
    $route(to, from) {
      // handling for searchbar in search (because of keep-alive for BaseSearchPage)

      const searchRouteName = '__classifieds_search'
      if (
        from.name.startsWith(searchRouteName) &&
        !to.name.startsWith(searchRouteName)
      ) {
        // we are leaving search clientSide
        this.onBeforeDestroy()
      } else if (
        to.name.startsWith(searchRouteName) &&
        from &&
        !from.name.startsWith(searchRouteName)
      ) {
        // we are entering search clientSide
        this.onCreated()
        this.onMounted()
      }
    },
    qHandler: {
      immediate: true,
      handler(newVal) {
        if (!this.simple && this.useLegacySearchbar && newVal?.values) {
          this.activeQ = newVal.values.value || ''
          this.searchQ = this.activeQ
          this.activeDisplayPhrase = this.activeQ
        }
      }
    }
  },
  beforeDestroy() {
    this.onBeforeDestroy()
  },
  created() {
    this.onCreated()
  },
  mounted() {
    this.onMounted()
    this.modalToggleFunc.setModalInSearch(true)
    this.modalToggleFunc.setHash('searchbar')
    this.modalToggleFunc.setAfterModalCloseCallback(this.afterModalClose)
    this.modalToggleFunc.setOnModalCloseCallback(this.onModalClose)

    this.$store.subscribe(mutation => {
      if (mutation.type === `${CLASSIFIED_SEARCH_NS}/${CLEAR_ALL}`) {
        this.closeSuggestions()
        this.activeQ = ''
        this.activeDisplayPhrase = ''
      }
    })

    if (this.$refs.searchbar === document.activeElement) {
      this.onFocus()
    }
  },
  methods: {
    ...mapMutations(CLASSIFIED_SEARCH_NS, {
      setShowSellers: SET_SHOW_SELLERS,
      setParam: SET_PARAM
    }),
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      loadSeoUrlSearch: 'loadSeoUrlSearch',
      search: 'search',
      changePage: 'changePage'
    }),
    ...mapMutations('classifieds/search', {
      setShowSellers: SET_SHOW_SELLERS
    }),
    onCreated() {
      if (this.useLegacySearchbar && this.$route.query.q) {
        this.activeQ = this.$route.query.q
        this.searchQ = this.activeQ
        this.activeDisplayPhrase = this.activeQ
      } else if (this.$route.query.activeq) {
        this.activeQ = this.$route.query.activeq
        this.searchQ = this.activeQ
        this.activeDisplayPhrase = this.activeQ
      }
    },
    onMounted() {
      this.$cache.reset()
      this.updateActiveQFromUrl()

      if (!this.isMobile) {
        this.addGlobalKeyDownListener()
      }

      this.placeholdersCheck()
    },
    onBeforeDestroy() {
      this.$cache.reset()
      this.removeGlobalKeyDownListener()
      clearInterval(this.placeholderInterval)
    },
    addGlobalKeyDownListener() {
      document.addEventListener('keydown', this.onGlobalKeyDown)
    },
    removeGlobalKeyDownListener() {
      document.removeEventListener('keydown', this.onGlobalKeyDown)
    },
    onInput() {
      // acts like vmodel but also works on mobile when input is changing
      this.activeQ = this.$refs.searchbar.value
      this.searchQ = this.activeQ
      this.activeDisplayPhrase = this.activeQ
      this.triggerAutocomplete()
    },
    triggerAutocomplete: debounce(async function() {
      if (this.dontFetchNextInput) {
        this.dontFetchNextFocus = false
      } else {
        await this.closeSuggestions() // this will also cancel any requests happening
        this.fetchSuggestions(this.searchQ)
      }
    }, 450),
    fetchSuggestions(q) {
      if (this.useLegacySearchbar) {
        if (q) {
          this.fetchLegacySuggestions(q)
        }
        this.fetchAutocomplete(q)
      } else {
        this.fetchAutocomplete(q)
        if (q) {
          this.fetchFilterSuggestions(q)
        }
      }

      if (!this.fetchingClassifieds) {
        if (q.length >= 3 && !this.useLegacySearchbar) {
          this.fetchSuggestedClassifieds(q)
        } else {
          this.suggestedClassifieds = null
          this.filterSuggestions = []
        }
      }
    },
    async fetchAutocomplete(q) {
      try {
        this.fetchingAutocomplete = true

        const sugg = await this.searchbarService.getAutocompleteSuggestions(
          q,
          this.selectedCategory,
          this.searchContext
        )

        this.afterSearchQ = q
        if (this.autocompleteSuggestions.length) {
          this.autocompleteSuggestions = []
        }
        this.activeSuggestionIndex = -1
        this.hoveredSuggestion = -1

        if (this.useLegacySearchbar) {
          // only add history autocompleteSuggestions
          this.autocompleteSuggestions = sugg
            .filter(s => s.fromHistory)
            .map(s => {
              return { ...s, type: SuggestionType.AUTOCOMPLETE }
            })
        } else {
          this.autocompleteSuggestions = sugg.map(s => {
            return { ...s, type: SuggestionType.AUTOCOMPLETE }
          })
        }
      } catch (error) {
        if (!this.$axios.isCancel(error)) {
          this.$logger.captureError(error)
        }
      } finally {
        this.fetchingAutocomplete = false
      }
    },
    async fetchLegacySuggestions(q) {
      try {
        this.fetchingLegacy = true

        const sugg = await this.searchbarService.getLegacySuggestions(
          q,
          this.selectedCategory
        )

        this.afterSearchQ = q
        if (this.legacySuggestions.length) {
          this.legacySuggestions = []
        }
        this.activeSuggestionIndex = -1
        this.hoveredSuggestion = -1

        this.legacySuggestions = sugg.map(s => {
          return { ...s, type: SuggestionType.LEGACY }
        })
      } catch (error) {
        if (!this.$axios.isCancel(error)) {
          this.$logger.captureError(error)
        }
      } finally {
        this.fetchingLegacy = false
      }
    },
    async fetchFilterSuggestions(q) {
      if (q && !this.fetchingQuery) {
        try {
          const result = await this.fetchQuerySuggestions(q)

          if (this.suggestAfterFetch) {
            await this.$nextTick()
            this.suggestAfterFetch = false
            await this.goToSuggesterResult(result)
          } else if (result?.suggestions) {
            this.filterSuggestions = result.suggestions.map(s => {
              return { ...s, type: SuggestionType.FILTER }
            })
          }
        } catch (error) {
          this.$logger.captureError(error)
        }
      }
    },
    async fetchQuerySuggestions(q) {
      try {
        this.fetchingQuery = true

        const {
          classified,
          suggestions,
          fallback
        } = await this.searchbarService.getQuerySuggest(
          q,
          this.selectedCategory
        )

        const clsfd = classified || null
        const results = suggestions
        let filteredResults
        if (!this.useLegacySearchbar && !this.simple) {
          filteredResults = results.filter(r => {
            if (r.args.length === 1) {
              const cat = r.args[0]
              // if we have only one arg and its the same category we are in
              if (
                cat.name === 'category' &&
                cat.values[0] &&
                cat.values[0] === this.currentCategoryId
              ) {
                return false
              }
            }
            // else it will get handled in FilterSuggestion
            return true
          })
        }

        const result = {
          classified: clsfd,
          suggestions: filteredResults || results,
          fallback
        }

        return result
      } catch (error) {
        if (!this.$axios.isCancel(error)) {
          this.$logger.captureError(error)
        }
      } finally {
        this.fetchingQuery = false
      }
    },
    async fetchSuggestedClassifieds(q) {
      try {
        this.fetchingClassifieds = true

        const results = await this.searchbarService.getSuggestedClssifieds(
          q,
          this.selectedCategory
        )

        this.suggestedClassifieds = results
      } catch (error) {
        if (!this.$axios.isCancel(error)) {
          this.$logger.captureError(error)
        }
      } finally {
        this.fetchingClassifieds = false
      }
    },
    async removeAutocompleteSuggestion(suggestionToDelete: any) {
      try {
        // const suggestionToDelete = this.autocompleteSuggestions[index]
        const deletedId = await this.searchbarService.removeAutocompleteSuggestion(
          suggestionToDelete.id
        )
        if (deletedId && deletedId === suggestionToDelete.id) {
          const index = this.autocompleteSuggestions.findIndex(
            s => s.id === suggestionToDelete.id
          )
          this.autocompleteSuggestions.splice(index, 1)
          this.$cache.reset()
        } else {
          throw new Error(
            'Delete request did not return the same id as suggestionToDelete'
          )
        }
      } catch (error) {
        this.$logger.captureError(error)
      }
    },
    async clearAllHistory() {
      try {
        const deletedCount = await this.searchbarService.clearAllHistory()
        if (deletedCount) {
          this.$cache.reset()
          this.autocompleteSuggestions = [
            ...this.autocompleteSuggestions.filter(s => !s.fromHistory)
          ]
        }
      } catch (error) {
        this.$logger.captureError(error)
      }
    },

    onGlobalKeyDown(e) {
      const key = e.keyCode
      if (key === KeyCode.SLASH) {
        const focusElem = document.activeElement
        const elemType = focusElem.type
        if (
          focusElem.tagName.toLowerCase() === 'input' &&
          [
            'text',
            'search',
            'email',
            'number',
            'password',
            'tel',
            'url'
          ].includes(elemType)
        ) {
          // focus is on an input text element
          return
        }
        e.preventDefault()
        this.focusSearchbar()
        window.scrollTo(0, 0)
      }
    },
    onKeydown(e) {
      if (this.suggestions.length === 0) {
        return
      }

      const key = e.keyCode

      switch (key) {
        case KeyCode.DOWN:
          this.onDown(e)
          break
        case KeyCode.UP:
          this.onUp(e)
          break
        case KeyCode.ESC:
          // hide suggestions
          e.preventDefault()
          this.closeSuggestions()
          this.blurSearchbar()
          break
      }
    },
    nextActivePlaceholder() {
      if (this.focused && !this.silentlyFocused) {
        return
      }
      this.fadePlaceholder = true
      setTimeout(() => {
        if (this.activePlaceholder + 1 > this.placeholders.length - 1) {
          this.activePlaceholder = 0
        } else {
          this.activePlaceholder++
        }
        this.fadePlaceholder = false
      }, 300) // 300 = same as transition time
    },
    async suggestActiveDisplayPhrase() {
      if (this.activeDisplayPhrase) {
        if (this.fetchingQuery) {
          // we are already fetching a query so when this is finished
          this.suggestAfterFetch = true
        } else {
          try {
            if (this.useLegacySearchbar) {
              if (!this.simple && this.inSearchRoute) {
                // if in search go the legacy way
                this.searchLegacy({
                  q: this.activeDisplayPhrase,
                  postForHistory: true
                })
              } else {
                // not in search - probably homepage
                // get first suggestion and go there
                const suggestions = await this.searchbarService.getLegacySuggestions(
                  this.activeDisplayPhrase,
                  this.selectedCategory
                )
                if (suggestions.length) {
                  this.postSuggestionForHistory(suggestions[0])
                  this.goToUrl(suggestions[0].seoUrl, false)
                }
              }
              this.closeSuggestions()
            } else {
              this.suggestAfterFetch = true
              await this.fetchFilterSuggestions(this.activeDisplayPhrase)
            }
          } catch (error) {
            this.$logger.captureError(error)
          }
        }
      }
    },
    async goToSuggesterResult(result: any) {
      try {
        this.closeSuggestions()
        const { classified, suggestions, fallback } = result

        if (fallback) {
          this.goToUrl(fallback)
        } else if (classified) {
          if (this.suggestions.length) {
            const i = this.suggestions.findIndex(
              s => s.seo_url === classified || s.seoUrl === classified
            )
            if (i >= 0) {
              this.onSuggestedClassifiedClick(i)
              return
            }
          }
          // if we found nothing, just go with router
          // this.goToUrl(classified)
          await this.$router.push({
            path: classified
          })
        }
        if (suggestions?.length) {
          // got to url
          this.postSuggestionForHistory(suggestions[0])
          await this.goToUrl(suggestions[0].url)
        }
      } catch (error) {
        this.$logger.captureError(error)
      }
    },
    async searchLegacy({
      q,
      updateUrl = true,
      url = '',
      postForHistory = false
    }: {
      q: string
      updateUrl: boolean
      url: string
      postForHistory: boolean
    }) {
      this.closeSuggestions()
      this.blurSearchbar()
      this.setShowSellers(false)

      this.changePage({ page: 1 })
      this.setParam({ name: 'q', value: q })

      if (this.fullscreen) {
        if (url) {
          this.linkToNavigate = url
        }
        this.disableFullscreen()
      }

      await this.search({
        updateUrl
      })
      if (postForHistory) {
        let urlToPost = window.location.pathname + window.location.search
        if (url) {
          urlToPost = url
        }
        const mockSuggestion = {
          displayPhrase: q,
          url: urlToPost
        }
        this.postSuggestionForHistory(mockSuggestion)
      }
    },
    onDown(e) {
      e.preventDefault()
      if (this.hoveredSuggestion > -1) {
        this.activeSuggestionIndex = this.hoveredSuggestion + 1
        this.hoveredSuggestion = -1
      } else {
        this.activeSuggestionIndex++
      }
      if (this.activeSuggestionIndex > this.suggestions.length - 1) {
        this.activeSuggestionIndex = -1
        this.activeQ = this.searchQ
        this.activeDisplayPhrase = this.searchQ
      }
    },
    onUp(e) {
      e.preventDefault()
      if (this.hoveredSuggestion > -1) {
        this.activeSuggestionIndex = this.hoveredSuggestion - 1
        this.hoveredSuggestion = -1
      } else {
        this.activeSuggestionIndex--
      }
      if (this.activeSuggestionIndex === -1) {
        this.activeQ = this.searchQ
        this.activeDisplayPhrase = this.searchQ
      } else if (this.activeSuggestionIndex < -1) {
        this.activeSuggestionIndex = this.suggestions.length - 1
      }
    },
    onMouseOver(index: number) {
      if (this.activeSuggestionIndex !== index) {
        this.activeSuggestionIndex = -1
        // this.activeDisplayPhrase = this.searchQ
        this.hoveredSuggestion = index
      }
    },
    async onEnter() {
      if (this.activeSuggestionIndex >= 0 && this.activeSuggestion) {
        switch (this.activeSuggestion.type) {
          case SuggestionType.FILTER: {
            this.onFilterSuggestionClick(
              this.activeSuggestion,
              this.activeSuggestionIndex
            )
            break
          }
          case SuggestionType.LEGACY: {
            this.onLegacySuggestionClick(
              this.activeSuggestion,
              this.activeSuggestionIndex
            )
            break
          }
          case SuggestionType.AUTOCOMPLETE: {
            this.onAutocompleteSuggestionClick(
              this.activeSuggestion,
              this.activeSuggestionIndex
            )
            break
          }
          case SuggestionType.CLASSIFIED: {
            this.onSuggestedClassifiedClick(this.activeSuggestionIndex)
            break
          }
          case SuggestionType.MORE:
            this.goToUrl(this.activeSuggestion.seoUrl)
            break
        }
        this.blurSearchbar()
      } else {
        this.dontFetchNextInput = true
        if (this.activeDisplayPhrase === '') {
          // hit enter with empty string
          this.closeSuggestions()
          this.goToSelectedCatRootSearch()
        } else {
          await this.suggestActiveDisplayPhrase()
        }
        this.blurSearchbar()
      }

      if (this.fullscreen) {
        this.disableFullscreen()
      }
    },
    onSuggestionClick(suggestion: any, index: number) {
      this.activeSuggestionIndex = index

      switch (suggestion.type) {
        case SuggestionType.FILTER:
          this.onFilterSuggestionClick(suggestion, index)
          break
        case SuggestionType.AUTOCOMPLETE:
          this.onAutocompleteSuggestionClick(suggestion, index)
          break
        case SuggestionType.LEGACY:
          this.onLegacySuggestionClick(suggestion, index)
          break
        case SuggestionType.CLASSIFIED:
          this.onSuggestedClassifiedClick(index)
          break
        case SuggestionType.MORE:
          this.goToUrl(suggestion.seoUrl)
          break
      }
    },
    onFilterSuggestionClick(suggestion: any) {
      this.postSuggestionForHistory(suggestion)
      if (suggestion.url) {
        this.clearSuggestionsAndFocus()
        // this.qFacet = ''
        this.goToUrl(suggestion.url)
      }
    },
    onLegacySuggestionClick(suggestion: any) {
      this.postSuggestionForHistory(suggestion)
      if (!this.simple && this.inSearchRoute) {
        // if in search go the legacy way
        if (suggestion.value.category) {
          this.setParam({ name: 'category', value: suggestion.value.category })
        }
        this.searchLegacy({
          q: suggestion.value.q,
          updateUrl: !this.fullscreen,
          url: suggestion.seoUrl
        })
      } else if (suggestion.seoUrl) {
        this.clearSuggestionsAndFocus()
        // this.qFacet = ''
        this.goToUrl(suggestion.seoUrl, false)
      }
    },
    onAutocompleteSuggestionClick(suggestion: any) {
      if (!suggestion.raw) {
        this.activeQ = suggestion.displayPhrase
        this.searchQ = this.activeQ
        this.activeDisplayPhrase = this.activeQ
        if (!suggestion.fromHistory) {
          this.postSuggestionForHistory(suggestion)
        }
      }

      if (suggestion.data.suggester) {
        this.suggestActiveDisplayPhrase()
      } else if (suggestion.data.url) {
        if (!suggestion.raw && !suggestion.data.query) {
          this.clearSuggestionsAndFocus()
          // this.qFacet = ''
        }
        this.goToUrl(suggestion.data.url)
      }
    },
    onSuggestedClassifiedClick(index: number) {
      if (this.fullscreen) {
        this.classifiedIndexToNavigate = index
        this.blurSearchbar()
        this.disableFullscreen()
      } else {
        this.triggerClassifiedAtIndex(index)
      }
    },
    triggerClassifiedAtIndex(index: number) {
      const clsfdSuggestion = this.$refs[`suggestion-${index}`]
      if (clsfdSuggestion && clsfdSuggestion[0]) {
        clsfdSuggestion[0].triggerClassified()
      }
    },
    postSuggestionForHistory(suggestion) {
      try {
        this.searchbarService.postSuggestionForHistory(
          this.getCategoryIdForHistory(suggestion),
          suggestion.url || suggestion.seoUrl || suggestion.data?.url,
          suggestion.displayPhrase || suggestion.title || suggestion.name,
          suggestion.uqtId,
          this.searchContext
        )
        this.$cache.reset()
      } catch (error) {
        this.$logger.captureError(error)
      }
    },
    getCategoryIdForHistory(suggestion) {
      return this.useLegacySearchbar && suggestion.category?.id
        ? suggestion.category.id
        : this.selectedCategory
    },
    async goToUrl(url: string, addActiveQ = true) {
      if (!url) {
        return
      }
      this.closeSuggestions()
      this.setShowSellers(false)

      let finalUrl = url
      if (addActiveQ) {
        finalUrl = this.getUrlWithActiveQ(url)
      }

      if (this.fullscreen) {
        this.linkToNavigate = finalUrl
        this.blurSearchbar()
        this.disableFullscreen()
      } else {
        this.blurSearchbar()
        if (this.isMobile && this.isFiltersPage) {
          this.disableFullscreen()
        }
        // this.$router.push({
        //   path: finalUrl
        // })
        //

        if (this.useLegacySearchbar || this.simple) {
          await this.$router.push({
            path: finalUrl
          })
        } else {
          await this.loadSeoUrlSearch({
            seoUrl: finalUrl
          })
        }
      }
    },
    updateActiveQFromUrl(path: string = '') {
      this.$nextTick(() => {
        const pathToUse = path || window.location.search

        if (this.useLegacySearchbar) {
          const { q } = qs.parse(pathToUse, {
            ignoreQueryPrefix: true
          })
          if (q) {
            this.activeQ = q
          }
        } else {
          const { activeq } = qs.parse(pathToUse, {
            ignoreQueryPrefix: true
          })
          if (activeq) {
            this.activeQ = activeq
          }
        }

        if (this.activeQ) {
          this.searchQ = this.activeQ
          this.activeDisplayPhrase = this.activeQ
        }
      })
    },
    goToSelectedCatRootSearch() {
      const currentCat = this.textSearchableCategories.filter(
        c => c.id === this.selectedCategory[0]
      )[0]
      if (currentCat) {
        window.location.href = currentCat.seoUrl
      } else if (this.forceCategory) {
        switch (this.forceCategory) {
          case CategoryId.VEHICLES:
            this.goToUrl(this.legacyUrlService.getSearchUrl())
            break
          case CategoryId.XYMA:
            this.goToUrl('/xyma/')
            break
          case CategoryId.PARTS:
            this.goToUrl('/parts/')
            break
          case CategoryId.JOBS:
            this.goToUrl('/jobs/')
            break
        }
      }
    },
    getUrlWithActiveQ(path: string) {
      let url: string = process.env.BASE_CLIENT_URL || defaultBaseClientUrl
      if (url.endsWith('/')) {
        url = url.substr(0, url.length - 1)
      }
      const fullUrl = new URL(url + path)

      if (this.activeQ) {
        fullUrl.searchParams.set('activeq', this.activeQ)
      } else {
        fullUrl.searchParams.delete('activeq')
      }

      return fullUrl.pathname + fullUrl.search
    },
    async afterModalClose() {
      this.blurSearchbar()
      if (this.linkToNavigate) {
        const link = this.linkToNavigate
        this.linkToNavigate = ''

        // await this.goToUrl(link)
        await this.$router.push({
          path: link
        })

        this.updateActiveQFromUrl(link)
      }
      if (this.classifiedIndexToNavigate > -1) {
        const clsfdIndex = this.classifiedIndexToNavigate
        this.classifiedIndexToNavigate = -1

        if (clsfdIndex > -1) {
          this.triggerClassifiedAtIndex(clsfdIndex)
        }
      }
    },
    onBlur() {
      this.focused = false
      // this.closeSuggestions()
    },
    clickedOutside() {
      if (this.fullscreen) {
        this.disableFullscreen()
      }
      this.closeSuggestions()
    },
    onFocus() {
      this.focused = true

      if (this.dontFetchNextInput) {
        this.dontFetchNextInput = false
      }

      if (this.dontFetchNextFocus) {
        this.dontFetchNextFocus = false
        return
      }

      // if it is mobile, show suggestions as modal
      if (this.isMobile) {
        this.fullscreen = true
        this.modalToggleFunc.modalOpen()
      }
      this.fetchSuggestions(this.activeQ)
    },
    onClick() {
      if (!this.isMobile && this.silentlyFocused && !this.showResults) {
        this.silentlyFocused = false
        this.onFocus()
      }
    },
    disableFullscreen() {
      this.modalToggleFunc.modalClose(false)
    },
    onModalClose() {
      // this.autocompleteSuggestions = []

      this.fullscreen = false
      this.focused = false
    },
    onSuggestionActive(suggestion: any, index: number, payload?: any) {
      this.activeSuggestionIndex = index
      switch (suggestion.type) {
        case SuggestionType.FILTER:
          this.onFilterSuggestionActive(suggestion)
          break
        case SuggestionType.AUTOCOMPLETE:
          this.onAutocompleteSuggestionActive(payload, suggestion)
          break
        case SuggestionType.LEGACY:
          this.onLegacySuggestionActive(suggestion)
          break
        case SuggestionType.CLASSIFIED:
        case SuggestionType.MORE:
          this.onSuggestedClassifiedActive(suggestion)
          break
      }
    },
    onAutocompleteSuggestionActive(txt: string, suggestion: any) {
      this.activeQ = txt
      this.activeSuggestion = suggestion

      if (suggestion?.displayPhrase) {
        this.activeDisplayPhrase = suggestion.displayPhrase
      }
    },
    onLegacySuggestionActive(suggestion: any) {
      this.activeQ = this.searchQ
      this.activeDisplayPhrase = this.searchQ
      this.activeSuggestion = suggestion
    },
    onFilterSuggestionActive(suggestion: any) {
      this.activeQ = this.searchQ
      this.activeDisplayPhrase = this.searchQ
      this.activeSuggestion = suggestion
    },
    onSuggestedClassifiedActive(suggestion: any) {
      this.activeQ = this.searchQ
      this.activeDisplayPhrase = this.searchQ
      this.activeSuggestion = suggestion
    },
    async closeSuggestions() {
      if (this.fetching) {
        await this.searchbarService.cancelRequests()

        // they just got cancelled and for some reason 'finally' does not run instantly after cancel
        this.fetchingAutocomplete = false
        this.fetchingLegacy = false
        this.fetchingQuery = false
        this.fetchingClassifieds = false

        this.$cache.reset()
      }

      this.activeSuggestion = null
      if (this.autocompleteSuggestions.length) {
        this.autocompleteSuggestions = []
      }

      if (this.filterSuggestions.length) {
        this.filterSuggestions = []
      }
      if (this.legacySuggestions.length) {
        this.legacySuggestions = []
      }

      this.suggestedClassifieds = null

      this.activeSuggestionIndex = -1
      this.hoveredSuggestion = -1
      this.$emit('suggestions-close', this.activeQ)
    },
    setFinalQuote(q) {
      if (q) {
        this.micRecording = false
        // this.$refs.autocomplete.$refs.searchbar.focus()
        this.activeQ = q
        this.$nextTick(() => {
          this.onInput()
        })
      }
    },
    setInterimQuote(q) {
      if (q) {
        this.activeQ = q
        this.$nextTick(() => {
          this.onInput()
        })
      }
    },
    setMicRecStatus(status) {
      this.micRecording = status
    },
    clearSuggestionsAndFocus() {
      this.closeSuggestions()
      this.silentFocus()
    },
    clearQ() {
      this.closeSuggestions()
      this.activeQ = ''
      this.activeDisplayPhrase = ''
      if (!this.isMobile) {
        this.dontFetchNextFocus = true
      }
      this.focusSearchbar()
    },
    async placeholdersCheck() {
      // reset in case they are already set
      this.activePlaceholder = 0
      clearInterval(this.placeholderInterval)

      await this.$nextTick()
      if (this.placeholders.length > 1) {
        const delay = 4000
        this.placeholderInterval = setInterval(
          this.nextActivePlaceholder,
          delay
        )
      }
    },
    onRootCategoryChange() {
      this.closeSuggestions()
      this.activeQ = ''
      this.afterSearchQ = ''
      this.searchQ = ''
      this.activeDisplayPhrase = ''
      this.placeholdersCheck()
      if (!this.isMobile) {
        this.silentFocus()
      }
    },
    silentFocus() {
      this.dontFetchNextFocus = true
      this.silentlyFocused = true
      this.focusSearchbar()
    },
    focusSearchbar() {
      this.$nextTick(() => {
        if (this.$refs.searchbar) {
          this.$refs.searchbar.focus()
        }
      })
    },
    blurSearchbar() {
      this.$nextTick(() => {
        if (this.$refs.searchbar) {
          this.$refs.searchbar.blur()
        }
      })
    },
    searchButtonClick() {
      if (this.activeDisplayPhrase) {
        this.closeSuggestions()
        this.onEnter()
      }
    },
    mobileBackClicked() {
      this.disableFullscreen()
      this.closeSuggestions()
    }
  }
})
