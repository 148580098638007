




















import { mapState, mapGetters } from 'vuex'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import CNewBadge from '~/components/shared/configurable/badge/CNewBadge.vue'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    CNewBadge
  },
  props: {
    isNew: { type: Boolean, default: false },
    url: { type: String, default: null },
    onlyIconOnMobile: { type: Boolean, default: false }
  },
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, {
      mapSearchUrl: state => state.mapSearchUrl,
      params: state => state.params,
      currentRoute: state => state.currentRoute
    }),
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      getCurrentCategoryId: 'getCurrentCategoryId'
    }),
    componentType() {
      return this.isMobile ? 'a' : 'div'
    },
    componentProps(): object {
      const props = {
        href: this.url || this.mapSearchUrl,
        class: 'tw-inline-block'
      }
      if (!this.isMobile) {
        delete props.href
      }

      return props
    },
    buttonLink() {
      return this.isMobile ? null : this.url || this.mapSearchUrl
    }
  }
})
