



























































































































































































































































































































































































































































































































import {
  faCalendarAlt,
  faClock,
  faEnvelope,
  faMapMarker,
  faPhone,
  faStar
} from '@fortawesome/free-solid-svg-icons'
import { mapState } from 'vuex'
import Category from '~/components/car/classifieds/search/results/rows/main/partials/Category.vue'
import Price from '~/components/car/classifieds/search/results/rows/main/partials/Price.vue'
import PricePerUnit from '~/components/car/classifieds/search/results/rows/main/partials/PricePerUnit.vue'
import Title from '~/components/car/classifieds/search/results/rows/main/partials/Title.vue'
import RowMixin from '~/components/car/classifieds/search/results/rows/main/RowMixin.vue'
import CHybridTelephone from '~/components/shared/configurable/telephone/CHybridTelephone.vue'
import {
  adminTimeDistanceFromNow,
  cappedTimeDistanceFromNow
} from '~/utils/date'
import InstallmentPrice from './partials/InstallmentPrice.vue'
import { USER_NS } from '~/store/modules/shared/user/state'
import CClassifiedThumbnail from '~/components/shared/configurable/classified/image/CClassifiedThumbnail.vue'
import Description from '~/components/car/classifieds/search/results/rows/main/partials/Description.vue'
import CClassifiedCompareButton from '~/components/shared/configurable/classified/compare/CClassifiedCompareButton.vue'
import DiscountPercentageTag from '~/components/car/classifieds/DiscountPercentageTag.vue'
import PromoRibbon from '~/components/car/classifieds/search/results/rows/main/partials/PromoRibbon.vue'
import PromoUser from '~/components/car/classifieds/search/results/rows/main/partials/PromoUser.vue'
import KeyFeaturesAndCategoriesMobile from '~/components/car/classifieds/search/results/rows/main/KeyFeaturesAndCategoriesMobile.vue'
import InstallmentsBadge from '~/components/car/classifieds/badges/InstallmentsBadge.vue'
import CBadge360 from '~/components/shared/configurable/badge/CBadge360.vue'
import { defineComponent } from '@nuxtjs/composition-api'
import MarketplaceRibbon from '~/components/car/classifieds/search/results/rows/main/partials/MarketplaceRibbon.vue'

export default defineComponent({
  components: {
    MarketplaceRibbon,
    CBadge360,
    InstallmentsBadge,
    KeyFeaturesAndCategoriesMobile,
    PromoUser,
    BackgroundJobsBadge: () =>
      import(
        '~/components/car/classifieds/search/results/rows/main/partials/BackgroundJobs/BackgroundJobsBadge.vue'
      ),
    PaidClassifiedInfo: () =>
      import('./partials/PaidInfo/PaidClassifiedInfo.vue'),
    PromoRibbon,
    CClassifiedThumbnail,
    Title,
    Category,
    Description,
    Price,
    DiscountPercentageTag,
    PricePerUnit,
    CHybridTelephone,
    CClassifiedCompareButton,
    InstallmentPrice
  },
  mixins: [RowMixin],
  computed: {
    ...mapState(USER_NS, {
      userType: 'type'
    }),
    lastModifiedStr() {
      if (this.userIsAdmin) {
        return adminTimeDistanceFromNow(this.classified.modified)
      }
      return cappedTimeDistanceFromNow(this.classified.modified, 60, {
        locale: this.$i18n.locale,
        addPrefix: false
      })
    },
    icons() {
      return {
        mapMarker: faMapMarker,
        clock: faClock,
        phone: faPhone,
        registration: faCalendarAlt,
        envelope: faEnvelope,
        star: faStar
      }
    }
  }
})
