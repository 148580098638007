import { render, staticRenderFns } from "./MarketplaceRibbon.vue?vue&type=template&id=67742833&scoped=true&"
import script from "./MarketplaceRibbon.vue?vue&type=script&lang=ts&"
export * from "./MarketplaceRibbon.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67742833",
  null
  
)

export default component.exports