



































































import Vue from 'vue'

import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope'
// @ts-ignore
import VueSocialSharing from 'vue-social-sharing'
import {
  faFacebookF,
  faFacebookMessenger,
  faViber,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import UrlShortenerService from '~/services/url/UrlShortenerService'
import { copyTextToClipboard } from '~/utils/dom'
import { computed, onMounted, ref, toRefs } from '@nuxtjs/composition-api'
import { useDep } from '~/compositions/dependency-container'
import { useI18n } from '~/compositions/i18n'
import { useUserAgent } from '~/compositions/user-agent'
import { useSnackbar } from '~/compositions/snackbar'
import { useApp } from '~/compositions/app'
import { ciXNoBg } from '~/icons/source/brand/x-no-bg'

export default Vue.extend({
  components: { 'social-sharing': VueSocialSharing },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    // Pass this prop to bypass url shortening and use this url instead
    url: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const { url } = toRefs(props)

    const shareUrl = ref(window.location.href)

    const urlShortenerService = useDep(UrlShortenerService)
    const { t } = useI18n()
    const { isPc } = useUserAgent()
    const snackbar = useSnackbar()
    const { isCar } = useApp()

    onMounted(async () => {
      if (url.value) {
        shareUrl.value = url.value
        return
      }

      try {
        const { shortUrl } = await urlShortenerService.shorten(
          window.location.href
        )
        shareUrl.value = shortUrl
      } catch (err) {
        shareUrl.value = window.location.href
      }
    })

    const twitterUser = computed(() =>
      isCar.value ? 'cargrofficial' : 'plotgr'
    )

    const facebookAppId = computed(() =>
      isCar.value ? '184669805568954' : '1634451253275200'
    )

    const messengerLink = computed(() =>
      isPc.value
        ? `https://www.facebook.com/dialog/send?app_id=${facebookAppId.value}&link=${shareUrl.value}&redirect_uri=${shareUrl.value}`
        : `fb-messenger://share/?link=${shareUrl.value}&app_id=${facebookAppId.value}`
    )

    const whatsappLink = computed(() => `https://wa.me/?text=${shareUrl.value}`)

    function copyToClipboard() {
      copyTextToClipboard(shareUrl.value)
      snackbar.success(t('link copied to clipboard'))
    }

    const networks = [
      {
        name: 'email',
        icon: faEnvelope,
        variant: 'blue',
        title: 'Email'
      },
      {
        name: 'facebook',
        icon: faFacebookF,
        variant: 'facebook',
        title: 'Facebook'
      },
      {
        name: 'twitter',
        fa: true,
        icon: ciXNoBg,
        variant: 'x',
        title: 'X'
      },
      {
        name: 'whatsapp',
        icon: faWhatsapp,
        variant: 'whatsapp',
        title: 'Whatsapp'
      },
      {
        name: 'messenger',
        icon: faFacebookMessenger,
        variant: 'light',
        class: 'text-messenger',
        title: 'Messenger'
      },
      {
        name: 'viber',
        icon: faViber,
        variant: 'viber',
        title: 'Viber'
      },
      {
        name: 'clipboard',
        icon: faLink,
        variant: 'blue',
        title: t('url')
      }
    ]

    return {
      shareUrl,
      networks,
      copyToClipboard,
      twitterUser,
      messengerLink,
      whatsappLink
    }
  }
})
