import { CIcon } from '~/icons/types'
import { CategoryId } from '~/models/category/types'
import { ciXyma } from '~/icons/source/brand/xyma'
import { ciPlotLabeled } from '~/icons/source/brand/plot-labeled'
import { ciParts } from '~/icons/source/regular/parts'
import { ciParts2 } from '~/icons/source/regular/parts2'
import { ciBike } from '~/icons/source/regular/bike'
import { ciBoat } from '~/icons/source/regular/boat'
import { ciBicycle } from '~/icons/source/regular/bicycle'
import { ciTruck } from '~/icons/source/regular/truck'
import { ciTrailer } from '~/icons/source/regular/trailer'
import { ciTaxi } from '~/icons/source/regular/taxi'
import { ciCaravan } from '~/icons/source/regular/caravan'
import { ciMotorhome } from '~/icons/source/regular/motorhome'
import { ciHobby } from '~/icons/source/regular/hobby'
import { ciGokart } from '~/icons/source/regular/gokart'
import { ciWatersport } from '~/icons/source/regular/watersport'
import { ciSnowsport } from '~/icons/source/regular/snowsport'
import { ciAirsport } from '~/icons/source/light/airsport'
import { ciVan } from '~/icons/source/regular/van'
import { ciBus } from '~/icons/source/regular/bus'
import { ciBuilder } from '~/icons/source/regular/builder'
import { ciSemitruck } from '~/icons/source/regular/semitruck'
import { ciSemitrailer } from '~/icons/source/regular/semitrailer'
import { ciTractor } from '~/icons/source/regular/tractor'
import { ciForklift } from '~/icons/source/regular/forklift'
import { ciJeepSuv } from '~/icons/source/regular/jeep-suv'
import { ciVanMinibus } from '~/icons/source/regular/van-mini-bus'
import { ciPickup } from '~/icons/source/regular/pickup'
import { ciSportsCar } from '~/icons/source/regular/sports-car'
import { ciCommercialCar } from '~/icons/source/regular/commercial-car'
import { ciCabrio } from '~/icons/source/regular/cabrio'
import { ciCoupeSport } from '~/icons/source/regular/coupe-sport'
import { ciCompactHatchback } from '~/icons/source/regular/compact-hatchback'
import { ciCombiCaravan } from '~/icons/source/regular/combi-caravan'
import { ciLimoSedan } from '~/icons/source/regular/limo-sedan'
import { ciTrailerCar } from '~/icons/source/regular/trailer-car'
import { ciJobs } from '~/icons/source/brand/jobs'
import { ciCar } from '~/icons/source/regular/car'
import { ciVehicleRentals } from '~/icons/source/brand/vehicle-rentals'
import { ciVehicles } from '~/icons/source/brand/vehicles'
import { ciCaravanHouse } from '~/icons/source/regular/caravanHouse'
import { ciContainer } from '~/icons/source/regular/container'
// alternative icons
import { ciXyma as ciXymaAlt } from '~/icons/source/duotone/xyma'
import { ciDeals } from '~/icons/source/duotone/deals'
import { ciJobs as ciJobsAlt } from '~/icons/source/duotone/jobs'
import { ciPartsAccessories } from '~/icons/source/duotone/parts-accessories'
import { ciRealEstate } from '~/icons/source/duotone/real-estate'
import { ciVehicleRentals as ciVehicleRentalsAlt } from '~/icons/source/duotone/vehicle-rentals'
import { ciVehicles as ciVehiclesAlt } from '~/icons/source/duotone/vehicles'
import { ciCar as ciCarAlt } from '~/icons/source/duotone/car'
import { ciBicycle as ciBicycleAlt } from '~/icons/source/duotone/bicycle'
import { ciCaravan as ciCaravanAlt } from '~/icons/source/duotone/caravan'
import { ciMotorcycle } from '~/icons/source/duotone/motorcycle'
import { ciBoat as ciBoatAlt } from '~/icons/source/duotone/boat'
import { ciTruck as ciTruckAlt } from '~/icons/source/duotone/truck'
import { ciRc } from '~/icons/source/duotone/rc'
import { ciTractor as ciTractorAlt } from '~/icons/source/duotone/tractor'
import { ciBus as ciBusAlt } from '~/icons/source/duotone/bus'
import { ciExcavator } from '~/icons/source/duotone/excavator'
import { ciForklift as ciForkliftAlt } from '~/icons/source/duotone/forklift'
import { ciCarService } from '~/icons/source/duotone/car-service'
// solid icons
import { ciVan as ciVanSolid } from '~/icons/source/solid/van'
import { ciTruck as ciTruckSolid } from '~/icons/source/solid/truck'
import { ciBus as ciBusSolid } from '~/icons/source/solid/bus'
import { ciExcavator as ciExcavatorSolid } from '~/icons/source/solid/excavator'
import { ciBigTruck as ciBigTruckSolid } from '~/icons/source/solid/big-truck'
import { ciTruckTrailer as ciTruckTrailerSolid } from '~/icons/source/solid/truck-trailer'
import { ciTractor as ciTractorSolid } from '~/icons/source/solid/tractor'
import {
  ciForklift as ciForkLiftSolid,
  ciForklift as ciForkliftSolid
} from '~/icons/source/solid/forklift'
import { ciTrailer as ciTrailerSolid } from '~/icons/source/solid/trailer'
import { ciTaxi as ciTaxiSolid } from '~/icons/source/solid/taxi'
import { ciCaravan as ciCaravanSolid } from '~/icons/source/solid/caravan'
import { ciCamper as ciCamperSolid } from '~/icons/source/solid/camper'
import { ciCaravanHouse as ciCaravanHouseSolid } from '~/icons/source/solid/caravan-house'
import { ciContainer as ciContainerSolid } from '~/icons/source/solid/container'
import { ciRc as ciRcSolid } from '~/icons/source/solid/rc'
import { ciGokart as ciGokartSolid } from '~/icons/source/solid/gokart'
import { ciWatersport as ciWaterSportSolid } from '~/icons/source/solid/watersport'
import { ciSnowmobile as ciSnowmobileSolid } from '~/icons/source/solid/snowmobile'
import { ciDrone as ciDroneSolid } from '~/icons/source/solid/drone'
import { ciParts as ciPartsSolid } from '~/icons/source/solid/parts'
import { ciRealEstate as ciRealEstateSolid } from '~/icons/source/solid/real-estate'
import { ciXyma as ciXymaSolid } from '~/icons/source/solid/xyma'
import { ciJobs as ciJobsSolid } from '~/icons/source/solid/jobs'
import { ciRentals as ciRentalsSolid } from '~/icons/source/solid/rentals'
import { ciCar as ciCarSolid } from '~/icons/source/solid/car'
import { ciMotorcycle as ciMotorcycleSolid } from '~/icons/source/solid/motorcycle'
import { ciBoat as ciBoatSolid } from '~/icons/source/solid/boat'
import { ciBicycle as ciBicycleSolid } from '~/icons/source/solid/bicycle'
import { ciWantedParts } from '~/icons/source/duotone/wanted-parts'

// Solid icons only
export const categoriesSolidIconsMappings: {
  [categoryId: number]: CIcon
} = Object.freeze({
  [CategoryId.VEHICLES]: ciCarSolid,
  [CategoryId.PARTS]: ciPartsSolid,
  [CategoryId.PLOT]: ciRealEstateSolid,
  [CategoryId.XYMA]: ciXymaSolid,
  [CategoryId.JOBS]: ciJobsSolid,
  [CategoryId.RENTALS]: ciRentalsSolid,
  [CategoryId.DEALS]: ciDeals,
  [CategoryId.CARS]: ciCarSolid,
  [CategoryId.BIKES]: ciMotorcycleSolid,
  [CategoryId.BOATS]: ciBoatSolid,
  [CategoryId.COMMERCIAL]: ciTruckSolid,
  [CategoryId.CAMPERS]: ciCaravanSolid,
  [CategoryId.BICYCLES]: ciBicycleSolid,
  [CategoryId.HOBBIES]: ciRcSolid,
  [CategoryId.PARTS_CARS]: ciCarSolid,
  [CategoryId.PARTS_BIKES]: ciMotorcycleSolid,
  [CategoryId.PARTS_TRUCKS]: ciTruckSolid,
  [CategoryId.PARTS_TRACTORS]: ciTractorSolid,
  [CategoryId.PARTS_TOOLS]: ciPartsSolid,
  [CategoryId.PARTS_BOATS]: ciBoatSolid,
  [CategoryId.PARTS_BICYCLES]: ciBicycleSolid,
  [CategoryId.PARTS_BUSES]: ciBusSolid,
  [CategoryId.PARTS_BUILDERS]: ciExcavatorSolid,
  [CategoryId.PARTS_CAMPERS]: ciCaravanSolid,
  [CategoryId.PARTS_FORKLIFTS]: ciForkliftSolid,
  [CategoryId.VANS]: ciVanSolid,
  [CategoryId.TRUCKS]: ciTruckSolid,
  [CategoryId.BUSES]: ciBusSolid,
  [CategoryId.BUILDERS]: ciExcavatorSolid,
  [CategoryId.SEMITRUCKS]: ciBigTruckSolid,
  [CategoryId.SEMITRAILERS]: ciTruckTrailerSolid,
  [CategoryId.TRACTORS]: ciTractorSolid,
  [CategoryId.FORKLIFTS]: ciForkLiftSolid,
  [CategoryId.TRAILERS]: ciTrailerSolid,
  [CategoryId.TAXIS]: ciTaxiSolid,
  [CategoryId.CARAVANS]: ciCaravanSolid,
  [CategoryId.MOTORHOMES]: ciCamperSolid,
  [CategoryId.CARAVANHOUSE]: ciCaravanHouseSolid,
  [CategoryId.CONTAINER]: ciContainerSolid,
  [CategoryId.RADIOCONTROLS]: ciRcSolid,
  [CategoryId.GOKARTS]: ciGokartSolid,
  [CategoryId.WATERSPORTS]: ciWaterSportSolid,
  [CategoryId.SNOWSPORTS]: ciSnowmobileSolid,
  [CategoryId.AIRSPORTS]: ciDroneSolid
})

export const categoriesIconsMappings: {
  [categoryId: number]: CIcon
} = Object.freeze({
  [CategoryId.XYMA]: ciXyma,
  [CategoryId.PLOT]: ciPlotLabeled,
  [CategoryId.JOBS]: ciJobs,
  [CategoryId.PARTS]: ciParts,
  [CategoryId.CARS]: ciCar,
  [CategoryId.VEHICLES]: ciVehicles,
  [CategoryId.BIKES]: ciBike,
  [CategoryId.BOATS]: ciBoat,
  [CategoryId.BICYCLES]: ciBicycle,
  [CategoryId.TRUCKS]: ciTruck,
  [CategoryId.TRAILERS]: ciTrailer,
  [CategoryId.TAXIS]: ciTaxi,
  [CategoryId.CARAVANS]: ciCaravan,
  [CategoryId.MOTORHOMES]: ciMotorhome,
  [CategoryId.RADIOCONTROLS]: ciHobby,
  [CategoryId.GOKARTS]: ciGokart,
  [CategoryId.WATERSPORTS]: ciWatersport,
  [CategoryId.SNOWSPORTS]: ciSnowsport,
  [CategoryId.AIRSPORTS]: ciAirsport,
  [CategoryId.VANS]: ciVan,
  [CategoryId.BUSES]: ciBus,
  [CategoryId.BUILDERS]: ciBuilder,
  [CategoryId.SEMITRUCKS]: ciSemitruck,
  [CategoryId.SEMITRAILERS]: ciSemitrailer,
  [CategoryId.TRACTORS]: ciTractor,
  [CategoryId.FORKLIFTS]: ciForklift,
  [CategoryId.HOBBIES]: ciHobby,
  [CategoryId.COMMERCIAL]: ciTruck,
  [CategoryId.CAMPERS]: ciCaravan,
  [CategoryId.JEEPSUV]: ciJeepSuv,
  [CategoryId.VANMINIBUS]: ciVanMinibus,
  [CategoryId.FARMERPICKUP]: ciPickup,
  [CategoryId.SPORTSCAR]: ciSportsCar,
  [CategoryId.COMMERCIALCAR]: ciCommercialCar,
  [CategoryId.CABRIO]: ciCabrio,
  [CategoryId.COUPESPORT]: ciCoupeSport,
  [CategoryId.COMPACT]: ciCompactHatchback,
  [CategoryId.COMBICARAVAN]: ciCombiCaravan,
  [CategoryId.LIMO]: ciLimoSedan,
  [CategoryId.TRAILER]: ciTrailerCar,
  [CategoryId.RENTALS]: ciVehicleRentals,
  [CategoryId.PARTS_CARS]: ciCar,
  [CategoryId.PARTS_TRACTORS]: ciTractor,
  [CategoryId.PARTS_BUILDERS]: ciBuilder,
  [CategoryId.PARTS_TRUCKS]: ciTruck,
  [CategoryId.PARTS_TOOLS]: ciParts2,
  [CategoryId.PARTS_FORKLIFTS]: ciForklift,
  [CategoryId.PARTS_BUSES]: ciBus,
  [CategoryId.PARTS_BIKES]: ciBike,
  [CategoryId.PARTS_BICYCLES]: ciBicycle,
  [CategoryId.PARTS_BOATS]: ciBoat,
  [CategoryId.PARTS_CAMPERS]: ciCaravan,
  [CategoryId.CARAVANHOUSE]: ciCaravanHouse,
  [CategoryId.CONTAINER]: ciContainer
})

// Alternative icons for new categories and filter design

export const alternativeCategoriesIconsMappings: {
  [categoryId: number]: CIcon
} = Object.freeze({
  [CategoryId.VEHICLES]: ciVehiclesAlt,
  [CategoryId.PARTS]: ciPartsAccessories,
  [CategoryId.PLOT]: ciRealEstate,
  [CategoryId.XYMA]: ciXymaAlt,
  [CategoryId.JOBS]: ciJobsAlt,
  [CategoryId.RENTALS]: ciVehicleRentalsAlt,
  [CategoryId.DEALS]: ciDeals,
  [CategoryId.CARS]: ciCarAlt,
  [CategoryId.BIKES]: ciMotorcycle,
  [CategoryId.BOATS]: ciBoatAlt,
  [CategoryId.COMMERCIAL]: ciTruckAlt,
  [CategoryId.CAMPERS]: ciCaravanAlt,
  [CategoryId.BICYCLES]: ciBicycleAlt,
  [CategoryId.HOBBIES]: ciRc,
  [CategoryId.PARTS_CARS]: ciCarAlt,
  [CategoryId.PARTS_BIKES]: ciMotorcycle,
  [CategoryId.PARTS_TRUCKS]: ciTruckAlt,
  [CategoryId.PARTS_TRACTORS]: ciTractorAlt,
  [CategoryId.PARTS_TOOLS]: ciPartsAccessories,
  [CategoryId.PARTS_BOATS]: ciBoatAlt,
  [CategoryId.PARTS_BICYCLES]: ciBicycleAlt,
  [CategoryId.PARTS_BUSES]: ciBusAlt,
  [CategoryId.PARTS_BUILDERS]: ciExcavator,
  [CategoryId.PARTS_CAMPERS]: ciCaravanAlt,
  [CategoryId.PARTS_FORKLIFTS]: ciForkliftAlt,
  [CategoryId.VANS]: ciVanSolid,
  [CategoryId.TRUCKS]: ciTruckSolid,
  [CategoryId.BUSES]: ciBusSolid,
  [CategoryId.BUILDERS]: ciExcavatorSolid,
  [CategoryId.SEMITRUCKS]: ciBigTruckSolid,
  [CategoryId.SEMITRAILERS]: ciTruckTrailerSolid,
  [CategoryId.TRACTORS]: ciTractorSolid,
  [CategoryId.FORKLIFTS]: ciForkLiftSolid,
  [CategoryId.TRAILERS]: ciTrailerSolid,
  [CategoryId.TAXIS]: ciTaxiSolid,
  [CategoryId.CARAVANS]: ciCaravanSolid,
  [CategoryId.MOTORHOMES]: ciCamperSolid,
  [CategoryId.CARAVANHOUSE]: ciCaravanHouseSolid,
  [CategoryId.CONTAINER]: ciContainerSolid,
  [CategoryId.RADIOCONTROLS]: ciRcSolid,
  [CategoryId.GOKARTS]: ciGokartSolid,
  [CategoryId.WATERSPORTS]: ciWaterSportSolid,
  [CategoryId.SNOWSPORTS]: ciSnowmobileSolid,
  [CategoryId.AIRSPORTS]: ciDroneSolid,
  [CategoryId.SERVICE]: ciCarService,
  [CategoryId.WANTED_PARTS]: ciWantedParts
})
