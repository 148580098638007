















































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Facet, PurchaseTypesEnum } from '~/models/search/types'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'

import RangeSearchHandler from '~/components/car/classifieds/search/facets/handlers/RangeSearchHandler.vue'
import LeasingOptionsSearchHandler from '~/components/car/classifieds/search/facets/handlers/LeasingOptionsSearchHandler.vue'
import LeasingExtrasSearchHandler from '~/components/car/classifieds/search/facets/handlers/LeasingExtrasSearchHandler.vue'
import { mapDeps } from '~/plugins/dependency-container/utils'
import SearchService from '~/services/search/SearchService'
import {
  emptyInstallmentsParams,
  emptyLeasingParams
} from '~/constants/search/facets'
import { Option } from '~/models/shared/types'
import CRadioButtonGroup from '~/components/shared/configurable/button/CRadioButtonGroup.vue'
import MarketplaceSearchHandler from '~/components/car/classifieds/search/facets/handlers/MarketplaceSearchHandler.vue'

export default Vue.extend({
  components: {
    CRadioButtonGroup,
    LeasingOptionsSearchHandler,
    LeasingExtrasSearchHandler,
    RangeSearchHandler,
    MarketplaceSearchHandler,
    WithPriceSearchHandler: () =>
      import(
        '~/components/car/classifieds/search/facets/handlers/WithPriceSearchHandler.vue'
      ),
    DiscountSearchHandler: () =>
      import(
        '~/components/car/classifieds/search/facets/handlers/DiscountSearchHandler.vue'
      ),
    WithOnlineShoppingSearchHandler: () =>
      import(
        '~/components/car/classifieds/search/facets/handlers/WithOnlineShoppingSearchHandler.vue'
      ),
    WholesalePriceSearchHandler: () =>
      import(
        '~/components/car/classifieds/search/facets/handlers/WholesalePriceSearchHandler.vue'
      ),
    CheapOnlySearchHandler: () =>
      import(
        '~/components/car/classifieds/search/facets/handlers/CheapOnlySearchHandler.vue'
      ),
    DealersOnlySearchHandler: () =>
      import(
        '~/components/car/classifieds/search/facets/handlers/DealersOnlySearchHandler.vue'
      ),
    FinanceOptionsSearchHandler: () =>
      import(
        '~/components/car/classifieds/search/facets/handlers/FinanceOptionsSearchHandler.vue'
      )
  },
  extends: BaseSearchHandler,
  data() {
    return {
      selectedPurchaseType: null
    }
  },
  computed: {
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      sortOptions: 'getSortOptions',
      getFacetByName: 'getFacetByName'
    }),
    ...mapDeps({ searchService: SearchService }),
    purchaseTypeOptions(): Option[] {
      const options = [
        {
          text: this.$t('price'.toString()),
          value: PurchaseTypesEnum.PRICE
        }
      ]
      if (this.hasInstallments) {
        options.push({
          text: this.$t('installments').toString(),
          value: PurchaseTypesEnum.INSTALLMENTS
        })
      }
      if (this.hasLeasing) {
        options.push({
          text: 'Leasing',
          value: PurchaseTypesEnum.LEASING
        })
      }
      return options
    },
    showButtonGroups() {
      return this.hasInstallments || this.hasLeasing
    },
    withPriceFacet(): object {
      return this.getFacetByName('with_price')
    },
    marketplaceFacet() {
      return this.getFacetByName('marketplace')
    },
    withOnlineShoppingFacet(): object {
      return this.getFacetByName('online_shopping')
    },
    discountFacet(): object {
      return this.getFacetByName('discount')
    },
    wholesalePriceFacet(): object {
      return this.getFacetByName('wholesale_price')
    },
    cheapOnlyFacet(): object {
      return this.getFacetByName('cheap_only')
    },
    visibilityFacet(): object {
      return this.getFacetByName('visibility')
    },
    financeOptionsFacet(): Facet {
      return this.getFacetByName('finance_options')
    },
    financeFacet(): Facet {
      return this.getFacetByName('finance')
    },
    leasingOptionsFacet(): Facet {
      return this.getFacetByName('leasing_options')
    },
    leasingExtrasFacet(): Facet {
      return this.getFacetByName('leasing_extras')
    },
    purchaseTypes(): PurchaseTypesEnum {
      return PurchaseTypesEnum
    },
    isSelectedPurchaseTypeFinance() {
      return this.selectedPurchaseType === PurchaseTypesEnum.INSTALLMENTS
    },
    isSelectedPurchaseTypeLeasing() {
      return this.selectedPurchaseType === PurchaseTypesEnum.LEASING
    },
    hasInstallments() {
      return this.financeOptionsFacet && this.financeOptionsFacet.visible
    },
    hasLeasing() {
      return this.leasingOptionsFacet && this.leasingOptionsFacet.visible
    }
  },
  methods: {
    onBackendValues() {
      const selected = this.financeFacet?.values?.selected || []
      if (selected.includes(PurchaseTypesEnum.INSTALLMENTS)) {
        this.selectedPurchaseType = PurchaseTypesEnum.INSTALLMENTS
      } else if (selected.includes(PurchaseTypesEnum.LEASING)) {
        this.selectedPurchaseType = PurchaseTypesEnum.LEASING
      } else {
        this.selectedPurchaseType = PurchaseTypesEnum.PRICE
      }
    },
    onClearAll() {
      this.selectedPurchaseType = PurchaseTypesEnum.PRICE
    },
    onClear() {
      this.emitParams([
        { name: 'sort', value: null },
        { name: 'price', value: null },
        { name: 'price-from', value: null },
        { name: 'price-to', value: null },
        { name: 'withprice', value: null },
        { name: 'discount', value: null },
        { name: 'wholesale', value: null },
        { name: 'dealersonly', value: null },
        { name: 'finance', value: null },
        { name: 'cheap', value: null },
        ...emptyInstallmentsParams,
        ...emptyLeasingParams
      ])
    },
    handlePurchaseTypeChange(purchaseType: PurchaseTypesEnum) {
      if (purchaseType === PurchaseTypesEnum.PRICE) {
        this.emitParams([
          {
            name: 'finance',
            value: null
          },
          ...emptyInstallmentsParams,
          ...emptyLeasingParams
        ])
        return
      }
      this.emitParams([
        {
          name: 'finance',
          value: purchaseType
        },
        ...emptyInstallmentsParams,
        ...emptyLeasingParams
      ])
    }
  }
})
