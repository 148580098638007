





















































import { STORAGE_NS, StorageState } from '~/store/modules/shared/storage/state'
import { faCompressAlt } from '@fortawesome/free-solid-svg-icons'
import CompareService from '~/services/compare/CompareService'
import { Trigger } from '~/models/app/trigger'
import { Position } from '~/models/app/position'
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  PropType,
  ref,
  toRefs,
  useRouter
} from '@nuxtjs/composition-api'
import { Timeout } from '~/models/timing/timeout'
import { useUserAgent } from '~/compositions/user-agent'
import { useApp } from '~/compositions/app'
import { useNamespacedStore } from '~/compositions/store'
import { CategoryId } from '~/models/category/types'
import { Classified } from '~/models/classified/types'
import { useI18n } from '~/compositions/i18n'
import { useDep } from '~/compositions/dependency-container'
import { useSnackbar } from '~/compositions/snackbar'

export default defineComponent({
  props: {
    categoryId: {
      type: Number as PropType<CategoryId>,
      default: null
    },
    prefix: {
      type: String,
      default: ''
    },
    iconOnly: {
      type: Boolean,
      default: false
    },
    classifiedId: {
      type: Number as PropType<Classified['id']>,
      default: null
    },
    bgFill: { type: Boolean, default: false },
    size: {
      type: [String, Number],
      default: '40'
    }
  },
  setup(props, { emit }) {
    const { categoryId, classifiedId } = toRefs(props)

    const popoverTimeout = ref<Timeout | null>()
    const isPopoverVisible = ref(false)

    const { isPc } = useUserAgent()
    const { isPlot } = useApp()
    const snackbar = useSnackbar()
    const { t } = useI18n()
    const router = useRouter()
    const compareService = useDep(CompareService)
    const { state: storageState } = useNamespacedStore<StorageState>(STORAGE_NS)

    const compareStorageItem = computed(() => storageState.state.compare)

    const isActive = computed(() => {
      const compareStorageCategoryItem =
        compareStorageItem.value && compareStorageItem.value[categoryId.value]

      if (
        !compareStorageCategoryItem ||
        !Array.isArray(compareStorageCategoryItem)
      ) {
        return false
      }

      return compareStorageCategoryItem.includes(classifiedId.value)
    })

    const buttonTitle = computed(() => {
      return isActive.value
        ? t('remove from comparison')
        : t('add to comparison list')
    })

    const compareUrl = computed(() => {
      return compareService.createCompareUrl(categoryId.value as number)
    })

    const hasCompareUrlIds = computed(() => {
      return compareUrl.value.match(/id/)
    })

    onBeforeUnmount(() => {
      if (popoverTimeout.value) {
        clearTimeout(popoverTimeout.value)
      }
    })

    function displayPopover() {
      popoverTimeout.value = setTimeout(() => {
        isPopoverVisible.value = false
      }, 3000)
      isPopoverVisible.value = true
    }

    function handleButtonClick() {
      emit('click')
      if (popoverTimeout.value) {
        clearTimeout(popoverTimeout.value)
      }

      if (!isPc.value) {
        snackbar.success(isActive.value ? t('removed from') : t('added to'), {
          time: 1800,
          classes: ['action-button-as-text', 'above-floating-button'],
          action: {
            text: t('comparison') as string,
            callback: () =>
              router.push({
                path: compareUrl.value
              })
          }
        })
      }
    }

    return {
      compareStorageItem,
      isPlot,
      isActive,
      isPc,
      buttonTitle,
      isPopoverVisible,
      compareUrl,
      handleButtonClick,
      displayPopover,
      Position,
      Trigger,
      faCompressAlt,
      hasCompareUrlIds
    }
  }
})
